<template>
	<section class="gardenManagementPage">
		<g-breadcrumb bread="客户金额管理" />
		<SearchForm>
			<el-form ref="ref_searchForm" :inline="true" :model="searchForm" label-width="80px">
				<el-form-item prop="realName">
					<el-input v-model.trim="searchForm.realName" placeholder="请输入客户姓名" />
				</el-form-item>
				<el-form-item prop="mobilePhone">
					<el-input v-model.trim="searchForm.mobilePhone" placeholder="请输入客户手机号码" />
				</el-form-item>
				<el-form-item label="" prop="merchantId">
					<el-select v-model="searchForm.merchantId" placeholder="请选择所属供应商" @change="changeDoctor" filterable clearable>
						<el-option v-for="item in options2" :key="item.merchantId" :label="item.merchantName"
							:value="item.merchantId" />
					</el-select>
				</el-form-item>
				<!-- <el-form-item>
					<el-input v-model.trim="searchForm.orgName1" placeholder="选择订单名" />
					<el-cascader v-model="searchForm.areaIds" style="width: 350px;" :options="orgAreaTree"
						placeholder="选择订单名" clearable filterable collapse-tags
						:props="{ multiple: true, checkStrictly: false, emitPath: false, label: 'orgName', value: 'orgId' }" />
				</el-form-item> -->

				<el-form-item>
					<el-button type="primary" @click="fetchData">搜 索</el-button>
				</el-form-item>
			</el-form>
		</SearchForm>
		<TableContainer title="客户列表">
			<!-- <template slot="button">
				<el-button type="success" @click="addGarden">添加订单</el-button>
			</template> -->
			<el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader" :cell-style="{ textAlign: 'center' }">
				<el-table-column prop="id" label="id" min-width="66" />
				<el-table-column prop="realName" label="客户名" min-width="100" />
				<el-table-column prop="mobilePhone" label="电话号码" min-width="100" />
				<el-table-column prop="birthday" label="生日" min-width="100" />
				<el-table-column prop="regName" label="注册时间" min-width="100" />
				<!-- <el-table-column prop="principalName" label="下单数" min-width="100" />
				<el-table-column prop="phone" label="支付数" min-width="100" />
				<el-table-column prop="remark" label="备注" min-width="100" show-overflow-tooltip /> -->
				<el-table-column width="200" label="操作">
					<template slot-scope="scope">
						<el-button type="text" class="reviewed" @click="editGarden(scope.row)">查看客户金额</el-button>
						<el-button type="text" class="reviewed" @click="viewUsers(scope.row)">查看用户</el-button>
					</template>
				</el-table-column>
			</el-table>
			<GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
				:size.sync="searchForm.size" @pagination="fetchData" />
		</TableContainer>
		<GDialog :dialog.sync="gardenDialog" @btnSure="submitThemeForm()" @btnClose="resetGardenForm()">
			<el-table :data="tableData1" stripe style="width: 100%">
				<!-- <el-table-column prop="id" label="id" min-width="160" /> -->
				<el-table-column prop="userName" label="客户名" min-width="100" />
				<el-table-column prop="paid" label="金额" min-width="100" />
				<!-- <el-table-column prop="birthday" label="生日" min-width="100" /> -->
				<!-- <el-table-column prop="principalName" label="下单数" min-width="100" />
				<el-table-column prop="phone" label="支付数" min-width="100" />
				<el-table-column prop="remark" label="备注" min-width="100" show-overflow-tooltip /> -->
				<el-table-column width="120" label="操作">
					<template slot-scope="scope">
						<el-button type="text" class="reviewed" @click="editGarden1(scope.row)">查看用户金额</el-button>
					</template>
				</el-table-column>
			</el-table>
		</GDialog>
		<GDialog :dialog.sync="gardenDialog2" @btnSure="submitThemeForm()" @btnClose="resetGardenForm()">
			<SearchForm>
				<el-date-picker v-model="daterange" :picker-options="pickerOptions" type="daterange" range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSearch" />
					<el-button type="primary" @click="fetchData1">搜 索</el-button>
			</SearchForm>
			<el-table :data="tableData2" stripe style="width: 100%">
				<!-- <el-table-column prop="id" label="id" min-width="160" /> -->
				<el-table-column prop="userName" label="用户名" min-width="100" />
				<!-- <el-table-column prop="realName" label="用户昵称" min-width="100" /> -->
				<el-table-column prop="paid" label="金额" min-width="100" />
				<el-table-column prop="birthday" label="生日" min-width="100" />
				<el-table-column prop="principalName" label="下单数" min-width="100" />
				<el-table-column prop="phone" label="支付数" min-width="100" />
				<el-table-column prop="remark" label="备注" min-width="100" show-overflow-tooltip />
				<!-- <el-table-column width="120" label="操作">
					<template slot-scope="scope">
						<el-button type="text" class="reviewed" @click="editGarden2(scope.row)">查看用户金额</el-button>
					</template>
				</el-table-column> -->
			</el-table>
		</GDialog>
		
		<GDialog :dialog.sync="gardenDialog3" @btnSure="submitThemeForm()"
			@btnClose="resetGardenForm()">
			<SearchForm>
				<el-form ref="ref_searchForm" :inline="true" :model="searchForm1" label-width="80px" @submit.native.prevent>
					<el-form-item label="" prop="realName">
						<el-input v-model="searchForm1.realName" placeholder="请输入用户名称" />
					</el-form-item>
					<el-form-item label="" prop="mobilePhone">
						<el-input v-model="searchForm1.mobilePhone" placeholder="请输入用户手机号码" />
					</el-form-item>
					<el-form-item label="" prop="flag">
						<el-select v-model="searchForm1.dicCode" placeholder="请选择用户类型" @change="changeUserType" filterable clearable>
							<el-option v-for="item in options3" :key="item.dicCode" :label="item.dicName"
								:value="item.dicCode" />
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="fetchData2">搜 索</el-button>
					</el-form-item>
				</el-form>
			</SearchForm>
			<el-table :data="tableData3" stripe style="width: 100%">
				<el-table-column prop="id" label="id" min-width="80" />
				<el-table-column prop="userName" label="用户名" min-width="100" />
				<el-table-column prop="realName" label="用户昵称" min-width="100" />
				<el-table-column prop="buyStatus" label="购买状态" min-width="100" />
				<el-table-column prop="address" label="用户地址" min-width="100" />
				<el-table-column prop="sexName" label="用户性别" min-width="100" />
				<el-table-column prop="regName" label="注册时间" min-width="100" />
				<!-- <el-table-column prop="principalName" label="下单数" min-width="100" />
				<el-table-column prop="phone" label="支付数" min-width="100" />
				<el-table-column prop="remark" label="备注" min-width="100" show-overflow-tooltip /> -->
				<!-- <el-table-column width="120" label="操作">
					<template slot-scope="scope">
						<el-button type="text" class="reviewed" @click="editGarden(scope.row)">查看用户表</el-button>
					</template>
				</el-table-column> -->
			</el-table>
			<GPagination v-show="total1 > 0" ref="ref_GPagination" :total="total1" :page.sync="searchForm1.page"
				:size.sync="searchForm1.size" @pagination="fetchData2" />
		</GDialog>
	</section>

</template>

<script>
	import {
		mapState
	} from 'vuex'
	import {
		postFindMfDockor,
		findMfDockorMoney,
		findMfDockorUserMoney,
		findMfDockorUser,
		findMfDockorAllUser,
		getDictoryInfoByCode1
		// delOrg
	} from '@api/gardenManagement/api_gardenManagement'
	import {
		findMfMch,
	} from '@api/userMangement/api_userMangement'
	export default {
		data() {
			return {
				daterange: '',
				beginTime: '',
				endTime: '',
				pickerOptions: {
					onPick({
						maxDate,
						minDate
					}) {
						// 当开始时间结束时间都选择了之后才执行搜索
						if (maxDate && minDate) {
							this.$nextTick(() => {
								that.handleSearch()
							})
						}
					}
				},

				currentRow: {},
				gardenDialog: {
					title: '客户金额表',
					visible: false
				},
				gardenDialog2: {
					title: '用户金额表',
					visible: false
				},
				gardenDialog3: {
					title: '用户信息表',
					visible: false
				},
				tableData: [],
				tableData1: [],
				tableData2: [],
				tableData3: [],
				options2: [],
				total: 0,
				total1: 0,
				searchForm: {
					merchantId: '',
					keyWord: "",
					mobilePhone: "",
					realName: "",
					page: 1,
					size: 20,
					startNum: 1
				},
				gardenForm: {
					areaId: '',
					orgName: '',
					principalName: '',
					phone: '',
					remark: ''
				},
				searchForm1: {
					parentId: 0,
					realName: "",
					mobilePhone: "",
					flag: "",
					page: 1,
					size: 20,
					startNum: 1
				},
				options3: [],
				// rules: {
				// 	areaId: [{
				// 		required: true,
				// 		message: '请选择归属',
				// 		trigger: 'change'
				// 	}],
				// 	orgName: [{
				// 		required: true,
				// 		message: '请输入园所名称',
				// 		trigger: 'blur'
				// 	}],
				// 	principalName: [{
				// 		required: true,
				// 		message: '请输入负责人',
				// 		trigger: 'blur'
				// 	}],
				// 	phone: [{
				// 			required: true,
				// 			message: '请输入手机号',
				// 			trigger: 'blur'
				// 		},
				// 		{
				// 			pattern: /^1[3456789]\d{9}$/,
				// 			message: '手机号格式错误',
				// 			trigger: 'blur'
				// 		}
				// 	]
				// }
			}
		},
		computed: {
			...mapState('commonModule', ['orgAreaTree'])
		},
		created() {
			this.getOptions()
			this.fetchData()
			this.findMfMch();
		},

		methods: {
			tabHeader() {
				return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
			},
			getOptions() {
				// 查询组织机构树-到片区；2到片区，3到园所
				this.$store.dispatch('commonModule/getOrgTree', 2)
			},
			findMfMch(){
				let params = {};
				findMfMch(params).then((res) =>{
					this.options2 = res
				})
			},
			changeDoctor(val){
				console.log(val)
				this.searchForm.merchantId = val 
			},
			changeUserType(val){
				this.searchForm1.flag = val
			},
			formatDate(date, fmt) {
				if (/(y+)/.test(fmt)) {
					fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
			}
				let o = {	
					'M+': date.getMonth() + 1,
					'd+': date.getDate(),
					'h+': date.getHours(),
					'm+': date.getMinutes(),
					's+': date.getSeconds()
				}
				for (let k in o) {
					if (new RegExp(`(${k})`).test(fmt)) {
						let str = o[k] + ''
						fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this.padLeftZero(str))
					}
				}
				return fmt
			},
			padLeftZero(str) {
				return ('00' + str).substr(str.length)
			},

			handleSearch(val) {
				// console.log(val,1111)
				// console.log(val[0],1111)
				let date = val[0];
				let date1 = val[1];
				this.beginTime = this.formatDate(date,'yyyy-MM-dd hh:mm:ss');
				this.endTime = this.formatDate(date1, 'yyyy-MM-dd hh:mm:ss');
				// console.log(this.beginTime,1111)
				
			},
			fetchData() {
				this.searchForm.type = 3
				postFindMfDockor(this.searchForm).then(res => {
					this.tableData = res.records
					this.total = res.total
				})
			},
			fetchData1(){
				let params = {
					startTime: this.beginTime,
					endTime: this.endTime,
					page: 1,
					size: 10
				
				}
				findMfDockorUserMoney(params).then(res => {
					this.tableData2 = res.records
					this.total = res.total
				})
			},
			fetchData2(){
				// let params = {
				// 	startTime: this.beginTime,
				// 	endTime: this.endTime,
				// 	page: 1,
				// 	size: 10
				
				// }
				findMfDockorAllUser(this.searchForm1).then(res => {
					this.tableData3 = res.records
					this.total1 = res.total
				})
			},
			// addGarden() {
			// 	this.gardenDialog.visible = true
			// 	this.gardenDialog.title = '添加订单'
			// },
			editGarden(row) {
				// console.log(row, 1111)
				
				console.log(this.searchForm1.parentId)
				this.gardenDialog.visible = true
				let params = {
					parentId: row.userId,
					page: 1,
					size: 10

				}
				findMfDockorMoney(params).then(res => {
					this.tableData1 = res.records
					this.total = res.total
				})
			},
			editGarden1(row) {
				console.log(row, 1111)
				// this.searchForm1.parentId = row.userId;
				this.gardenDialog2.visible = true
				let params = {
					parentId: row.userId,
					page: 1,
					size: 10

				}
				findMfDockorUserMoney(params).then(res => {
					this.tableData2 = res.records
					this.total = res.total
				})
			},
			deleteGarden(row) {
				this.$confirm('是否确认删除?', '提示', {
					type: 'warning',
					closeOnClickModal: false
				}).then(() => {
					this.currentRow = row
					delOrg({
						orgId: row.id
					}).then(res => {
						this.$message.success('删除成功！')
						if (this.total % this.searchForm.size === 1) {
							this.searchForm.page = this.searchForm.page - 1 === 0 ? 1 : this.searchForm
								.page - 1
						}
						this.fetchData()
					})
				})
			},
			
			viewUsers(row){
				this.searchForm1.parentId = row.userId;
				this.gardenDialog3.visible = true
				let params = {
					parentId: row.userId,
					page: 1,
					size: 10
					
				}
				findMfDockorAllUser(params).then(res => {
				  this.tableData3 = res.records
				  this.total1 = res.total
				})
				getDictoryInfoByCode1().then(res =>{
					console.log(res,1111)
					this.options3 = res
				})
				
			},

			// submitForm(formName) {
			// 	this.$refs[formName].validate(valid => {
			// 		if (!valid) {
			// 			return false
			// 		}
			// 		const params = {
			// 			...this.gardenForm
			// 		}
			// 		let _api
			// 		if (this.gardenDialog.title === '添加园所') {
			// 			_api = addOrg
			// 		} else {
			// 			_api = updateOrg
			// 			params.orgId = this.currentRow.id
			// 		}
			// 		_api(params).then(res => {
			// 			this.$message.success(this.gardenDialog.title + '成功！')
			// 			this.resetGardenForm('ref_gardenForm')
			// 			this.fetchData()
			// 		})
			// 	})
			// },
			submitThemeForm() {
				this.gardenDialog.visible = false
			},
			resetGardenForm(formName) {
				// this.$refs[formName].resetFields()
				this.gardenDialog.visible = false
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.reviewed {
		color: #38b9be;
	}
</style>
